<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-dialog v-model="newServiceDialog" width="800">
      <v-card class="cards pa-2">
        <span v-show="false">{{ reactivity }}</span>
        <v-card-title class="text-right">
          <h5>افزودن خدمت به درخواست</h5>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="visitType != 'دندانپزشکی' ? freeNames : dentistFreeNames"
            label="نام خدمت"
            item-text="text"
            item-value="id"
            class="mx-auto my-1"
            outlined
            dense
            placeholder="انتخاب کنید"
            v-model="newService.name"
          ></v-autocomplete>
          <vuetify-money
            placeholder="قیمت"
            label="قیمت (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
            class="mx-auto my-1"
            v-model="newService.cost"
          />
          <vuetify-money
            placeholder="هزینه"
            label="هزینه مصرفی (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
            class="mx-auto my-1"
            v-model="newService.expense"
          />
          <vuetify-money
            placeholder="هزینه"
            label="هزینه اقلام حفاظتی (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
            class="mx-auto my-1"
            v-model="newService.clinicShare"
          />
          <v-select
            :items="computedServiceTypes"
            label="شیوه ارائه خدمت"
            class="mx-auto my-1"
            outlined
            dense
            placeholder="انتخاب کنید"
            v-model="newService.type"
            v-if="visitType != 'دندانپزشکی'"
          />

          <v-btn
            class="primary-btn"
            @click="
              visitType != 'دندانپزشکی'
                ? addFreeService(newService)
                : addFreeDentistService(newService)
            "
            :disabled="
              !newService.name || !newService.cost || visitType != 'دندانپزشکی'
                ? !newService.type
                : false
            "
            >ثبت</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>درخواست جدید</h3>
          </v-col>
          <v-col>
            <v-dialog v-model="newPatientDialog" persistent width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="openNewPatient"
                  class="primary-btn"
                  style="float: left"
                >
                  <v-icon left> person_add </v-icon>
                  افزودن بیمار
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <h3>ثبت بیمار جدید</h3>
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          label="نام بیمار"
                          v-model="newPatient.firstName"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          label="نام‌خانوادگی بیمار"
                          v-model="newPatient.lastName"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row class="time-row">
                      <v-col class="pt-0" md="6" cols="12">
                        <v-select
                          label="جنسیت"
                          v-model="newPatient.gender"
                          :items="genders"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-select
                      ></v-col>
                      <v-col class="pt-0" md="6" cols="12">
                        <v-text-field
                          label="شماره موبایل"
                          v-model="newPatient.mobile"
                          type="number"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                    </v-row>

                    <v-row class="time-row">
                      <v-col class="pt-0" md="6" cols="12">
                        <v-text-field
                          label="کد ملی"
                          v-model="newPatient.natCode"
                          type="number"
                          :rules="[isID]"
                          outlined
                          dense
                        ></v-text-field
                      ></v-col>
                      <v-col class="pt-0" md="6" cols="12">
                        <v-text-field
                          label="شماره بیمه"
                          v-model="newPatient.insurNo"
                          outlined
                          dense
                          type="number"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row class="time-row">
                      <v-col class="pt-0">
                        <v-select
                          label="نوع بیمه"
                          v-model="newPatient.insurType"
                          :items="insurTypes"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-select
                      ></v-col>
                    </v-row>
                    <v-row class="time-row">
                      <v-col class="pt-0" md="6" cols="12">
                        <v-checkbox
                          v-model="newPatient.insuranceLastSheet"
                          class="lastSheet-checkBox"
                          color="primary"
                          label="تا آخرین صفحه دفترچه"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col class="pt-0" md="6" cols="12">
                        <v-text-field
                          outlined
                          dense
                          type="text"
                          append-icon="calendar_today"
                          v-model="newPatient.insuranceExpirationDate"
                          label="تاریخ انقضا دفترچه"
                          :editable="true"
                          class="date-input mt-2"
                          :disabled="newPatient.insuranceLastSheet"
                        >
                          <template #append>
                            <span id="addPatientinsuranceExpirationDate">
                              <v-icon class="calender-icon"
                                >mdi-calendar-blank</v-icon
                              >
                            </span>
                          </template>
                        </v-text-field>

                        <date-picker
                          v-model="newPatient.insuranceExpirationDate"
                          element="addPatientinsuranceExpirationDate"
                          color="#00a7b7"
                      /></v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="red-btn"
                    @click="
                      newPatientDialog = false;
                      newPatient = {};
                    "
                  >
                    لغو
                  </v-btn>
                  <v-btn
                    class="primary-btn"
                    @click="submitNewPatient()"
                    :loading="addPatientBtnLoading"
                    :disabled="
                      newPatient.firstName == '' ||
                      newPatient.lastName == '' ||
                      newPatient.gender == '' ||
                      newPatient.mobile == '' ||
                      !checkNatCode(newPatient.natCode)
                    "
                  >
                    ثبت
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <h4 class="primary--text mb-4 text-right">فیلتر بیماران</h4>
          <div>
            <v-row class="inputs-row">
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  label="نام"
                  v-model="filterName"
                  class="search-input"
                  dense
                  outlined
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کدملی"
                  no-wheel
                  class="search-input"
                  dense
                  outlined
                  v-model="filterNatCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کد اشتراک"
                  no-wheel
                  class="search-input"
                  dense
                  outlined
                  v-model="filterCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? filterPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-btn
                class="secondary-btn mb-1 ms-3 me-3"
                @click="filterPatients()"
                :disabled="
                  (filterName == '' &&
                    filterNatCode == '' &&
                    filterCode == '') ||
                  Busy
                "
              >
                اعمال فیلتر
                <v-icon right>tune</v-icon>
              </v-btn>
            </v-row>

            <v-row>
              <v-col cols="12" class="details-hidden">
                <v-progress-circular
                  v-if="Busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-if="!Busy"
                  v-model="patientName"
                  :items="patientNames"
                  @change="patientVisitsNumber()"
                  item-text="text"
                  item-value="id"
                  class="search-input"
                  dense
                  outlined
                  label="نام بیمار"
                ></v-autocomplete>
              </v-col>
              <!--<v-col
                cols="3"
                class="pa-0 mt-6"
                v-if="visitsNumbers >= 0 && patientName && !visitsBusy"
              >
                <span class="border pa-2 common-text">
                  تعداد مراجعه بیمار: {{ visitsNumbers }}
                </span>
              </v-col>-->
            </v-row>

            <div
              class="my-5"
              v-if="
                patientInfo.visitsNumbers >= 0 && patientName && !visitsBusy
              "
            >
              <h4 class="divider line one-line">اطلاعات بیمار</h4>

              <v-row class="mx-2">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="4"
                  class="text-right mt-3 px-0"
                >
                  <div class="w-100 border text-center px-5 py-3 rounded-lg">
                    <span class="primary--text pi-text font-weight-bold">
                      کد اشتراک:
                      {{
                        patientNames.filter((x) => x.id == patientName)[0].code
                      }}
                    </span>
                  </div></v-col
                >
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"
                  class="text-right mt-3"
                >
                  <div class="w-100 border text-center px-5 py-3 rounded-lg">
                    <span class="primary--text pi-text font-weight-bold">
                      تعداد مراجعه بیمار:
                      {{
                        patientInfo.visitsNumbers
                          ? patientInfo.visitsNumbers
                          : "-"
                      }}
                    </span>
                  </div></v-col
                >
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"
                  class="text-right mt-3 px-0"
                >
                  <div
                    class="w-100 border text-center px-5 py-3 rounded-lg"
                    :class="patientInfo.mobileStatus ? 'correct' : 'incorrect'"
                  >
                    <span class="primary--text pi-text font-weight-bold">
                      شماره موبایل :
                      {{ patientInfo.mobile ? patientInfo.mobile : "-" }}
                    </span>
                  </div></v-col
                >

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="2"
                  xl="2"
                  class="text-left pa-0 mt-6"
                >
                  <v-btn
                    :class="
                      patientInfo.mobileStatus ? 'secondary-btn' : 'red-btn'
                    "
                    @click="
                      changeMobileDialog = true;
                      newMobile = patientInfo.mobile;
                    "
                    ><span>ویرایش موبایل</span>
                  </v-btn>
                </v-col>
              </v-row>
              <hr class="w-50 mt-8 mx-auto" />
              <v-dialog v-model="changeMobileDialog" width="600">
                <v-card class="pa-3">
                  <v-card-title class="mb-5">ویرایش تلفن بیمار</v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="newMobile"
                      outlined
                      dense
                      label="شماره موبایل جدید"
                      type="number"
                      :placeholder="patientInfo.mobile"
                    >
                    </v-text-field>
                    <v-btn
                      class="primary-btn submit-btn pa-2 me-2 ms-2"
                      @click="changeMobileNumber()"
                    >
                      ثبت
                    </v-btn>
                    <v-btn
                      class="red-btn pa-2"
                      @click="changeMobileDialog = false"
                    >
                      لغو
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-row class="mt-5 mx-2">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="4"
                  class="text-right px-0"
                >
                  <div class="w-100 border text-center px-5 py-3 rounded-lg">
                    <span class="primary--text pi-text font-weight-bold">
                      نوع بیمه:
                      {{
                        patientInfo.insuranceId
                          ? insurTypes.filter(
                              (x) => x.id == patientInfo.insuranceId
                            )[0].text
                          : "-"
                      }}
                    </span>
                  </div></v-col
                >
                <v-col cols="12" sm="6" md="6" lg="3" xl="3" class="text-right">
                  <div class="w-100 border text-center px-5 py-3 rounded-lg">
                    <span class="primary--text pi-text font-weight-bold">
                      شماره بیمه :
                      {{ patientInfo.insurNo ? patientInfo.insurNo : "-" }}
                    </span>
                  </div></v-col
                >
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="3"
                  xl="3"
                  class="text-right px-0"
                >
                  <div class="w-100 border text-center px-5 py-3 rounded-lg">
                    <span class="primary--text pi-text font-weight-bold">
                      تاریخ انقضا دفترچه:
                      {{
                        patientInfo.insuranceLastSheet
                          ? "تا آخرین صفحه"
                          : patientInfo.insuranceExpirationDate
                          ? patientInfo.insuranceExpirationDate
                          : "-"
                      }}
                    </span>
                  </div></v-col
                >
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="2"
                  xl="2"
                  class="text-left pa-0 mt-4"
                >
                  <v-btn
                    class="secondary-btn"
                    @click="editInsuranceDialog = true"
                    :disabled="!patientName"
                    ><span>ویرایش بیمه</span>
                  </v-btn>
                </v-col>
              </v-row>

              <hr class="mb-8 mt-10" color="00a7b7" />
            </div>

            <v-row class="mt-0 time-row mb-2 mt-4">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateDate">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateDate"
                    label=" تاریخ  "
                    :editable="true"
                    class="date-input"
                    disabled
                  >
                  </v-text-field>
                </span>
                <!-- تاریخ و ساعت در تعریف درخواست برای پذیرش بسته است  -->
                <!-- <date-picker
                  v-model="dateDate"
                  readonly
                  disabled
                  element="dateDate"
                  color="#00a7b7"
                /> -->
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTime">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="dateTime"
                    label=" ساعت "
                    :editable="true"
                    class="date-input"
                    disabled
                  >
                  </v-text-field>
                </span>
                <!-- تاریخ و ساعت در تعریف درخواست برای پذیرش بسته است  -->
                <!-- <date-picker
                  v-model="dateTime"
                  readonly
                  element="dateTime"
                  color="#00a7b7"
                  type="time"
                /> -->
              </v-col>
            </v-row>

            <v-row class="time-row">
              <v-col
                class="pt-0"
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="
                  patientInfo.insuranceId
                    ? insurTypes.filter(
                        (x) => x.code == patientInfo.insuranceId
                      )[0].text != 'آزاد' &&
                      insurTypes.filter(
                        (x) => x.code == patientInfo.insuranceId
                      )[0].text != 'بیرونی'
                    : ''
                "
              >
                <v-text-field
                  outlined
                  dense
                  v-model="insurancePageNo"
                  label="شماره صفحه دفترچه"
                  :editable="true"
                  class="pa-0"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pt-0">
                <v-select
                  v-model="visitType"
                  :items="requestTypes"
                  item-text="text"
                  item-value="value"
                  label="نوع خدمت"
                  class="w-100"
                  menu-props="auto"
                  hide-details
                  outlined
                  dense
                  @change="
                    clinicServices();
                    deFaultDoc();
                  "
                ></v-select>
              </v-col>

              <v-dialog v-model="editInsuranceDialog" width="600">
                <v-card class="pa-3">
                  <v-card-title class="mb-5">ویرایش اطلاعات بیمه</v-card-title>
                  <v-card-text>
                    <v-select
                      label="نوع بیمه"
                      v-model="insuranceInfo.insuranceId"
                      :items="insurTypes"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                    ></v-select>

                    <div
                      v-if="
                        insuranceInfo.insuranceId
                          ? insurTypes.filter(
                              (x) => x.code == insuranceInfo.insuranceId
                            )[0].text != 'آزاد' &&
                            insurTypes.filter(
                              (x) => x.code == insuranceInfo.insuranceId
                            )[0].text != 'بیرونی'
                          : ''
                      "
                    >
                      <v-checkbox
                        v-model="insuranceInfo.insuranceLastSheet"
                        class="lastSheet-checkBox mt-0"
                        color="primary"
                        label="تا آخرین صفحه دفترچه"
                      >
                      </v-checkbox>
                      <v-text-field
                        v-model="insuranceInfo.insurNo"
                        outlined
                        dense
                        label="شماره بیمه"
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="insuranceInfo.insuranceExpirationDate"
                        label="تاریخ انقضا دفترچه"
                        :editable="true"
                        class="date-input mt-2"
                        :disabled="insuranceInfo.insuranceLastSheet"
                      >
                        <template #append>
                          <span id="insuranceExpirationDateEdit">
                            <v-icon class="calender-icon"
                              >mdi-calendar-blank</v-icon
                            >
                          </span>
                        </template>
                      </v-text-field>
                      <date-picker
                        v-model="insuranceInfo.insuranceExpirationDate"
                        element="insuranceExpirationDateEdit"
                        color="#00a7b7"
                        :disabled="insuranceInfo.insuranceLastSheet"
                      />
                    </div>

                    <v-btn
                      class="primary-btn submit-btn pa-2 me-2 ms-2"
                      @click="editPatientInsurance()"
                      :disabled="
                        insuranceInfo.insuranceId
                          ? insurTypes.filter(
                              (x) => x.code == insuranceInfo.insuranceId
                            )[0].text != 'آزاد' &&
                            insurTypes.filter(
                              (x) => x.code == insuranceInfo.insuranceId
                            )[0].text != 'بیرونی'
                            ? !insuranceInfo.insuranceId ||
                              !insuranceInfo.insurNo ||
                              (!insuranceInfo.insuranceLastSheet &&
                                !insuranceInfo.insuranceExpirationDate)
                            : false
                          : true
                      "
                    >
                      ثبت
                    </v-btn>
                    <v-btn
                      class="red-btn pa-2"
                      @click="editInsuranceDialog = false"
                    >
                      لغو
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
            <div class="mb-3" v-if="visitType">
              <div class="mb-3" v-if="visitType == 'public'">
                <v-card>
                  <v-card-title class="ps-0 pe-0">
                    <h3>جدول خدمات درمانگاه عمومی</h3></v-card-title
                  >
                  <v-card-text class="ps-0 pe-0">
                    <div>
                      <v-row class="mt-3">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.docId"
                            :items="computedDoctors"
                            item-text="text"
                            item-value="id"
                            label="نام پزشک"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.nurseId"
                            :items="nurseNames"
                            clearable
                            item-text="text"
                            item-value="id"
                            label="نام پرستار"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-checkbox
                            label="بیمار خارج از درمانگاه"
                            id="isOut"
                            color="indigo"
                            v-model="isOut"
                            name="isOut"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-col v-if="isOut" class="ps-0 pe-0"
                            ><v-text-field
                              v-model="outDoctor"
                              label="پزشک نویسنده نسخه"
                              outlined
                              dense
                            ></v-text-field
                          ></v-col>
                        </v-col>
                      </v-row>
                      <v-text-field
                        v-model="serviceFilter"
                        prepend-inner-icon="mdi-magnify"
                        label="جستجو"
                        single-line
                        hide-details
                        clearable
                        rounded
                        filled
                        class="text-right w-80 mb-2 search-input"
                      ></v-text-field>
                      <div>
                        <!-- here -->
                        <v-btn
                          @click="
                            newServiceDialog = true;
                            newService = {};
                          "
                          class="primary-btn my-5"
                          v-if="deviceType == 'mobile'"
                          >افزودن خدمت</v-btn
                        >
                        <b-table
                          v-else
                          responsive
                          small
                          show-empty
                          :items="freeServiceItems"
                          :fields="freeServiceFields"
                          empty-text="رکوردی برای نمایش وجود ندارد"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.value }}
                            </div>
                          </template>
                          <template v-slot:cell(operation)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              <v-btn
                                class="primary-btn pa-3"
                                @click="addFreeService(data.item)"
                                :disabled="
                                  !data.item.name ||
                                  !data.item.cost ||
                                  !data.item.type
                                "
                                >افزودن</v-btn
                              >
                            </div>
                          </template>
                          <template v-slot:cell(cost)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle; width 150px"
                            >
                              <vuetify-money
                                v-model="data.item.cost"
                                placeholder="قیمت"
                                :valueWhenIsEmpty="whenIsEmpty"
                                :options="vMoneyOptions"
                                type="number"
                                outlined
                                dense
                                class="mx-auto my-1"
                              />
                            </div>
                          </template>
                          <template v-slot:cell(clinicShare)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle; width 150px"
                            >
                              <vuetify-money
                                v-model="data.item.clinicShare"
                                placeholder="هزینه"
                                :valueWhenIsEmpty="whenIsEmpty"
                                :options="vMoneyOptions"
                                type="number"
                                outlined
                                dense
                                class="mx-auto my-1"
                              />
                            </div>
                          </template>
                          <template v-slot:cell(expense)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle; width 150px"
                            >
                              <vuetify-money
                                v-model="data.item.expense"
                                placeholder="هزینه"
                                :valueWhenIsEmpty="whenIsEmpty"
                                :options="vMoneyOptions"
                                type="number"
                                outlined
                                dense
                                class="mx-auto my-1"
                              />
                            </div>
                          </template>
                          <template v-slot:cell(type)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              <v-select
                                :items="[
                                  'خدمات پرستاری',
                                  'ویزیت',
                                  'خدمات پزشک',
                                  'اقلام مصرفی',
                                ]"
                                v-model="data.item.type"
                                outlined
                                dense
                                placeholder="انتخاب کنید"
                              />
                            </div>
                          </template>

                          <template v-slot:cell(name)="data">
                            <div
                              style="
                                text-align: center;
                                vertical-align: middle;
                                width: 100%;
                              "
                            >
                              <v-autocomplete
                                v-model="data.item.name"
                                :items="freeNames"
                                item-text="text"
                                item-value="id"
                                outlined
                                dense
                                placeholder="انتخاب کنید"
                                :menu-props="{ left: true, offsetX: true }"
                                style="z-index: 1000"
                              ></v-autocomplete>
                            </div>
                          </template>
                          <template v-slot:cell(patientCost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.item.cost }}
                            </div>
                          </template>
                        </b-table>

                        <!-- here -->
                        <b-table
                          responsive
                          show-empty
                          empty-text="خدمتی برای نمایش وجود ندارد"
                          empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                          :fields="computedServiceFields"
                          :items="serviceItems"
                          :current-page="serviceCurrentPage"
                          :per-page="servicePerPage"
                          :busy="serviceBusy"
                          :filter="serviceFilter"
                          @filtered="serviceonFiltered"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.value }}
                            </div>
                          </template>
                          <template v-slot:cell(index)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{
                                data.index +
                                servicePerPage * (serviceCurrentPage - 1) +
                                1
                              }}
                            </div>
                          </template>
                          <template v-slot:cell(patientCost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(clinicShare)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(cost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(operation)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              <v-btn
                                class="primary-btn pa-3"
                                @click="addtoNewService(data.item)"
                              >
                                افزودن
                              </v-btn>
                            </div>
                          </template>
                          <div
                            slot="table-busy"
                            class="text-center primary--text my-2"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              class="align-middle"
                            ></v-progress-circular>
                          </div>
                        </b-table>
                        <v-pagination
                          v-model="serviceCurrentPage"
                          :length="Math.ceil(serviceTotalRows / servicePerPage)"
                          :total-visible="5"
                          prev-icon="arrow_back"
                          next-icon="arrow_forward"
                        ></v-pagination>
                      </div>

                      <hr />

                      <h3 class="mt-3 mb-3">
                        <strong>خدمات درخواستی</strong>
                      </h3>

                      <b-table
                        :fields="computedServiceFields"
                        :items="newItems"
                        :current-page="newCurrentPage"
                        :per-page="newPerPage"
                        :filter="newFilter"
                        @filtered="onFiltered"
                        responsive
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.index + newPerPage * (newCurrentPage - 1) + 1
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(clinicShare)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(description)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.item.description.substr(0, 100) +
                              (data.item.description.length > 100 ? "..." : "")
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(patientCost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              outlined
                              @click="delService(data.index)"
                              class="pa-2 red-btn"
                              ><v-icon>remove</v-icon></v-btn
                            >
                          </div>
                        </template>
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="newCurrentPage"
                        :length="Math.ceil(newTotalRows / newPerPage)"
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                      ></v-pagination>
                      <hr />
                      <h5 class="text-black mb-3 ms-3 me-3">
                        <strong
                          >قیمت کل:
                          {{
                            newItems
                              .map((x) => x.patientCost)
                              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                              .toLocaleString()
                          }} </strong
                        >ریال
                      </h5>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="mb-3" v-if="visitType == 'expert'">
                <v-card>
                  <v-card-title class="ps-0 pe-0"
                    ><h3>جدول خدمات درمانگاه تخصصی</h3></v-card-title
                  >
                  <v-card-text class="ps-0 pe-0">
                    <div>
                      <v-row class="mt-3">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.docId"
                            :items="computedDoctors"
                            item-text="text"
                            item-value="id"
                            label="نام پزشک"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.nurseId"
                            :items="nurseNames"
                            clearable
                            item-text="text"
                            item-value="id"
                            label="نام پرستار"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-checkbox
                            label="بیمار خارج از درمانگاه"
                            id="isOut"
                            color="indigo"
                            v-model="isOut"
                            name="isOut"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col
                          v-if="isOut"
                          cols="12"
                          sm="12"
                          md="6"
                          lg="6"
                          xl="6"
                        >
                          <v-text-field
                            v-model="outDoctor"
                            label="پزشک نویسنده نسخه"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-text-field
                        v-model="serviceFilter"
                        prepend-inner-icon="mdi-magnify"
                        label="جستجو"
                        single-line
                        hide-details
                        clearable
                        filled
                        rounded
                        class="text-right w-80 mb-2 search-input"
                      ></v-text-field>

                      <div>
                        <!-- here -->
                        <v-btn
                          @click="
                            newServiceDialog = true;
                            newService = {};
                          "
                          class="primary-btn my-5"
                          v-if="deviceType == 'mobile'"
                          >افزودن خدمت</v-btn
                        >

                        <b-table
                          v-else
                          responsive
                          small
                          show-empty
                          :items="freeServiceItems"
                          :fields="freeServiceFields"
                          empty-text="رکوردی برای نمایش وجود ندارد"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.value }}
                            </div>
                          </template>
                          <template v-slot:cell(operation)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              <v-btn
                                class="primary-btn pa-3"
                                @click="addFreeService(data.item)"
                                :disabled="
                                  !data.item.name ||
                                  !data.item.cost ||
                                  !data.item.type
                                "
                                >افزودن</v-btn
                              >
                            </div>
                          </template>
                          <template v-slot:cell(cost)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle; width 150px"
                            >
                              <vuetify-money
                                v-model="data.item.cost"
                                placeholder="قیمت"
                                :valueWhenIsEmpty="whenIsEmpty"
                                :options="vMoneyOptions"
                                type="number"
                                outlined
                                dense
                                class="mx-auto my-1"
                              />
                            </div>
                          </template>
                          <template v-slot:cell(clinicShare)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle; width 150px"
                            >
                              <vuetify-money
                                v-model="data.item.clinicShare"
                                placeholder="هزینه"
                                :valueWhenIsEmpty="whenIsEmpty"
                                :options="vMoneyOptions"
                                type="number"
                                outlined
                                dense
                                class="mx-auto my-1"
                              />
                            </div>
                          </template>
                          <template v-slot:cell(expense)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle; width 150px"
                            >
                              <vuetify-money
                                v-model="data.item.expense"
                                placeholder="هزینه"
                                :valueWhenIsEmpty="whenIsEmpty"
                                :options="vMoneyOptions"
                                type="number"
                                outlined
                                dense
                                class="mx-auto my-1"
                              />
                            </div>
                          </template>
                          <template v-slot:cell(type)="data">
                            <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              <v-select
                                :items="['ویزیت تخصصی', 'خدمات تخصصی']"
                                v-model="data.item.type"
                                class="mx-auto my-1"
                                outlined
                                dense
                                placeholder="انتخاب کنید"
                              />
                            </div>
                          </template>

                          <template v-slot:cell(name)="data">
                            <div
                              style="
                                text-align: center;
                                vertical-align: middle;
                                width: 100%;
                              "
                            >
                              <v-autocomplete
                                v-model="data.item.name"
                                :items="freeNames"
                                item-text="text"
                                item-value="id"
                                class="mx-auto my-1"
                                style="z-index: 1000"
                                outlined
                                dense
                                placeholder="انتخاب کنید"
                                :menu-props="{ left: true, offsetX: true }"
                              ></v-autocomplete>
                            </div>
                          </template>
                          <template v-slot:cell(patientCost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.item.cost }}
                            </div>
                          </template>
                        </b-table>

                        <!-- here -->
                        <b-table
                          responsive
                          show-empty
                          empty-text="خدمتی برای نمایش وجود ندارد"
                          empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                          :fields="computedServiceFields"
                          :items="serviceItems"
                          :current-page="serviceCurrentPage"
                          :per-page="servicePerPage"
                          :busy="serviceBusy"
                          :filter="serviceFilter"
                          @filtered="serviceonFiltered"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.value }}
                            </div>
                          </template>
                          <template v-slot:cell(index)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{
                                data.index +
                                servicePerPage * (serviceCurrentPage - 1) +
                                1
                              }}
                            </div>
                          </template>
                          <template v-slot:cell(patientCost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(clinicShare)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(cost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(operation)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              <v-btn
                                class="primary-btn pa-3"
                                @click="addtoNewService(data.item)"
                              >
                                افزودن
                              </v-btn>
                            </div>
                          </template>
                          <div
                            slot="table-busy"
                            class="text-center primary--text my-2"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              class="align-middle"
                            ></v-progress-circular>
                          </div>
                        </b-table>
                        <v-pagination
                          v-model="serviceCurrentPage"
                          :length="Math.ceil(serviceTotalRows / servicePerPage)"
                          :total-visible="5"
                          prev-icon="arrow_back"
                          next-icon="arrow_forward"
                        ></v-pagination>
                      </div>

                      <hr />

                      <h5 class="mb-3">
                        <strong>خدمات درخواستی</strong>
                      </h5>

                      <b-table
                        :fields="computedServiceFields"
                        :items="newItems"
                        :current-page="newCurrentPage"
                        :per-page="newPerPage"
                        :filter="newFilter"
                        @filtered="onFiltered"
                        responsive
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.index + newPerPage * (newCurrentPage - 1) + 1
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(clinicShare)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(description)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.item.description.substr(0, 100) +
                              (data.item.description.length > 100 ? "..." : "")
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(patientCost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              outlined
                              @click="delService(data.index)"
                              class="pa-2 red-btn"
                              ><v-icon>remove</v-icon></v-btn
                            >
                          </div>
                        </template>
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="newCurrentPage"
                        :length="Math.ceil(newTotalRows / newPerPage)"
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                      ></v-pagination>
                      <hr />
                      <h5 class="text-black mb-3 ms-3 me-3">
                        <strong
                          >قیمت کل:
                          {{
                            newItems
                              .map((x) => x.patientCost)
                              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                              .toLocaleString()
                          }} </strong
                        >ریال
                      </h5>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <!-- NOTE  dental-->
              <div class="mb-3" v-if="visitType == 'دندانپزشکی'">
                <v-card>
                  <v-card-title class="ps-0 pe-0">
                    <h3>جدول خدمات و افزودن خدمات مورد نیاز</h3>
                  </v-card-title>
                  <v-card-text class="ps-0 pe-0">
                    <div>
                      <v-row class="mt-3">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.docId"
                            :items="computedDoctors"
                            item-text="text"
                            item-value="id"
                            label="نام پزشک"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.nurseId"
                            :items="nurseNames"
                            clearable
                            item-text="text"
                            item-value="id"
                            label="نام پرستار"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-checkbox
                            label="بیمار خارج از درمانگاه"
                            id="isOut"
                            color="indigo"
                            v-model="isOut"
                            name="isOut"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col
                          v-if="isOut"
                          cols="12"
                          sm="12"
                          md="6"
                          lg="6"
                          xl="6"
                        >
                          <v-text-field
                            v-model="outDoctor"
                            label="پزشک نویسنده نسخه"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-text-field
                        v-model="dentistServiceFilter"
                        prepend-inner-icon="mdi-magnify"
                        label="جستجو"
                        single-line
                        hide-details
                        clearable
                        rounded
                        filled
                        class="text-right w-80 mb-2 search-input"
                      ></v-text-field>
                      <v-btn
                        @click="
                          newServiceDialog = true;
                          newService = {};
                        "
                        class="primary-btn my-5"
                        v-if="deviceType == 'mobile'"
                        >افزودن خدمت</v-btn
                      >
                      <b-table
                        v-else
                        responsive
                        small
                        show-empty
                        :items="dentistFreeServiceItems"
                        :fields="dentistFreeServiceFields"
                        empty-text="رکوردی برای نمایش وجود ندارد"
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              class="primary-btn pa-3"
                              @click="addFreeDentistService(data.item)"
                              :disabled="!data.item.name || !data.item.cost"
                              >افزودن</v-btn
                            >
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                          <div
                            style="text-align: center; vertical-align: middle; width 150px"
                          >
                            <vuetify-money
                              v-model="data.item.cost"
                              placeholder="قیمت"
                              :valueWhenIsEmpty="whenIsEmpty"
                              :options="vMoneyOptions"
                              type="number"
                              outlined
                              dense
                              class="mx-auto my-1"
                            />
                          </div>
                        </template>
                        <template v-slot:cell(clinicShare)="data">
                          <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                          <div
                            style="text-align: center; vertical-align: middle; width 150px"
                          >
                            <vuetify-money
                              v-model="data.item.clinicShare"
                              placeholder="هزینه"
                              :valueWhenIsEmpty="whenIsEmpty"
                              :options="vMoneyOptions"
                              type="number"
                              outlined
                              dense
                              class="mx-auto my-1"
                            />
                          </div>
                        </template>
                        <template v-slot:cell(expense)="data">
                          <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                          <div
                            style="text-align: center; vertical-align: middle; width 150px"
                          >
                            <vuetify-money
                              v-model="data.item.expense"
                              placeholder="هزینه"
                              :valueWhenIsEmpty="whenIsEmpty"
                              :options="vMoneyOptions"
                              type="number"
                              outlined
                              dense
                              class="mx-auto my-1"
                            />
                          </div>
                        </template>

                        <template v-slot:cell(name)="data">
                          <div
                            style="
                              text-align: center;
                              vertical-align: middle;
                              width: 100%;
                            "
                          >
                            <v-autocomplete
                              v-model="data.item.name"
                              :items="dentistFreeNames"
                              item-text="text"
                              item-value="id"
                              class="mx-auto my-1"
                              outlined
                              dense
                              placeholder="انتخاب کنید"
                              :menu-props="{ left: true, offsetX: true }"
                              style="z-index: 1000"
                            ></v-autocomplete>
                          </div>
                        </template>
                        <template v-slot:cell(patientCost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.item.cost }}
                          </div>
                        </template>
                      </b-table>

                      <b-table
                        responsive
                        show-empty
                        empty-text="خدمتی برای نمایش وجود ندارد"
                        empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                        :fields="computedDentistServiceFields"
                        :items="dentistItems"
                        :current-page="dentistServiceCurrentPage"
                        :per-page="dentistServicePerPage"
                        :busy="serviceBusy"
                        :filter="dentistServiceFilter"
                        @filtered="dentistServiceonFiltered"
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.index +
                              dentistServicePerPage *
                                (dentistServiceCurrentPage - 1) +
                              1
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(patientCost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(clinicShare)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <!-- NOTE dokme -->
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              class="primary-btn pa-3"
                              @click="addtoNewDentistService(data.item)"
                            >
                              افزودن
                            </v-btn>
                          </div>
                        </template>
                        <!-- NOTE DOKME -->
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="dentistServiceCurrentPage"
                        :length="
                          Math.ceil(dentistTotalRows / dentistServicePerPage)
                        "
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                      ></v-pagination>

                      <hr />

                      <h5 class="mb-3">
                        <strong>خدمات درخواستی</strong>
                      </h5>

                      <b-table
                        :fields="computedDentistServiceFields"
                        :items="dentistNewItems"
                        :current-page="dentistNewCurrentPage"
                        :per-page="dentistNewPerPage"
                        :filter="dentistNewFilter"
                        @filtered="dentistonFiltered"
                        responsive
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.index +
                              dentistNewPerPage * (dentistNewCurrentPage - 1) +
                              1
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(clinicShare)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(description)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.item.description.substr(0, 100) +
                              (data.item.description.length > 100 ? "..." : "")
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(patientCost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              outlined
                              @click="dentistDelService(data.index)"
                              class="pa-2 red-btn"
                              ><v-icon>remove</v-icon></v-btn
                            >
                          </div>
                        </template>
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="dentistNewCurrentPage"
                        :length="
                          Math.ceil(dentistNewTotalRows / dentistNewPerPage)
                        "
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                      ></v-pagination>
                      <hr />
                      <h5 class="text-black mb-3 ms-3 me-3">
                        <strong
                          >قیمت کل:
                          {{
                            dentistNewItems
                              .map((x) => x.patientCost)
                              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                              .toLocaleString()
                          }} </strong
                        >ریال
                      </h5>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <!-- NOTE dental -->

              <!-- NOTE paraclinic -->
              <div class="mb-3" v-if="visitType == 'پاراکلینیک'">
                <v-card>
                  <v-card-title>
                    جدول خدمات و افزودن خدمات مورد نیاز
                  </v-card-title>
                  <v-card-text>
                    <div>
                      <v-row class="mt-3">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.docId"
                            :items="computedDoctors"
                            item-text="text"
                            item-value="id"
                            label="نام پزشک"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-progress-circular
                            v-if="busy"
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-autocomplete
                            v-if="!busy"
                            v-model="thisPageShiftDetails.nurseId"
                            :items="nurseNames"
                            clearable
                            item-text="text"
                            item-value="id"
                            label="نام پرستار"
                            class="pa-0"
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-checkbox
                          label="بیمار خارج از درمانگاه"
                          id="isOut"
                          color="indigo"
                          v-model="isOut"
                          name="isOut"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-row
                        v-if="isOut"
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                      >
                        <v-col
                          ><v-text-field
                            v-model="outDoctor"
                            label="پزشک نویسنده نسخه"
                          ></v-text-field
                        ></v-col>
                        <v-col>&nbsp;</v-col>
                      </v-row>
                      <v-text-field
                        v-model="serviceFilter"
                        prepend-inner-icon="mdi-magnify"
                        label="جستجو"
                        single-line
                        hide-details
                        clearable
                        filled
                        rounded
                        class="text-right w-80 mb-2 search-input"
                      ></v-text-field>
                      <div v-if="visitType == 'پارکلینیک'">
                        <b-table
                          responsive
                          show-empty
                          empty-text="خدمتی برای نمایش وجود ندارد"
                          empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                          :fields="computedServiceFields"
                          :items="serviceItems"
                          :current-page="serviceCurrentPage"
                          :per-page="servicePerPage"
                          :busy="serviceBusy"
                          :filter="serviceFilter"
                          @filtered="serviceonFiltered"
                        >
                          <template v-slot:head()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.label }}
                            </div>
                          </template>
                          <template v-slot:cell()="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ data.value }}
                            </div>
                          </template>
                          <template v-slot:cell(index)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{
                                data.index +
                                servicePerPage * (serviceCurrentPage - 1) +
                                1
                              }}
                            </div>
                          </template>
                          <template v-slot:cell(patientCost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(clinicShare)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(cost)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              {{ Number(data.value).toLocaleString() }}
                            </div>
                          </template>
                          <template v-slot:cell(operation)="data">
                            <div
                              style="text-align: center; vertical-align: middle"
                            >
                              <v-btn
                                class="primary-btn"
                                @click="addtoNewService(data.item)"
                              >
                              </v-btn>
                            </div>
                          </template>
                          <div
                            slot="table-busy"
                            class="text-center primary--text my-2"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              class="align-middle"
                            ></v-progress-circular>
                          </div>
                        </b-table>
                        <v-pagination
                          v-model="serviceCurrentPage"
                          :length="Math.ceil(serviceTotalRows / servicePerPage)"
                          :total-visible="5"
                          prev-icon="arrow_back"
                          next-icon="arrow_forward"
                          style="float: center"
                        ></v-pagination>
                      </div>

                      <hr />

                      <h5 class="mb-3">
                        <strong>خدمات درخواستی</strong>
                      </h5>

                      <b-table
                        :fields="computedServiceFields"
                        :items="newItems"
                        :current-page="newCurrentPage"
                        :per-page="newPerPage"
                        :filter="newFilter"
                        @filtered="onFiltered"
                        responsive
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.index + newPerPage * (newCurrentPage - 1) + 1
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(clinicShare)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(description)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{
                              data.item.description.substr(0, 100) +
                              (data.item.description.length > 100 ? "..." : "")
                            }}
                          </div>
                        </template>
                        <template v-slot:cell(patientCost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              outlined
                              class="red-btn pa-2"
                              @click="delService(data.index)"
                              ><v-icon>remove</v-icon></v-btn
                            >
                          </div>
                        </template>
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="newCurrentPage"
                        :length="Math.ceil(newTotalRows / newPerPage)"
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                        style="float: center"
                      ></v-pagination>
                      <hr />
                      <h5 class="text-black mb-3 ms-3 me-3">
                        <strong
                          >قیمت کل:
                          {{
                            newItems
                              .map((x) => x.patientCost)
                              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                              .toLocaleString()
                          }} </strong
                        >ریال
                      </h5>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <!-- NOTE paraclicnic -->
              <div v-else-if="visitType == 'مشاوره'">
                <hr class="mb-2 mt-4" />
                <v-row class="mt-3">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-progress-circular
                      v-if="busy"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <v-autocomplete
                      v-if="!busy"
                      v-model="thisPageShiftDetails.docId"
                      :items="computedDoctors"
                      item-text="text"
                      item-value="id"
                      label="نام پزشک"
                      class="pa-0"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <vuetify-money
                      v-model="price"
                      label="هزینه(﷼)"
                      :valueWhenIsEmpty="whenIsEmpty"
                      :options="vMoneyOptions"
                      type="number"
                      outlined
                      dense
                      style=""
                    />
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="transferType == 'charity'">
                <v-col>
                  <div class="d-flex float-end mb-4">
                    <h5 class="primary--text font-weight-bold">
                      موجودی صندوق دیوار مهربانی سلامت:
                    </h5>
                    <h5 class="ms-2 grey--text text--darken-3">
                      {{ Number(generalCharityBox).toLocaleString() + " ریال" }}
                    </h5>
                  </div>
                </v-col>
              </v-row>
              <v-row class="time-row mt-1">
                <v-col>
                  <v-select
                    v-if="
                      newItems
                        .map((x) => x.patientCost)
                        .reduce((a, b) => parseInt(a) + parseInt(b), 0) == 0 &&
                      visitType != 'مشاوره' &&
                      visitType != 'دندانپزشکی'
                    "
                    v-model="paymentStatus"
                    :items="paymentStatusOptions2"
                    item-text="text"
                    item-value="value"
                    label="وضعیت پرداخت"
                    outlined
                    dense
                  ></v-select>
                  <v-select
                    v-else
                    v-model="paymentStatus"
                    :items="paymentStatusOptions"
                    item-text="text"
                    item-value="value"
                    label="وضعیت پرداخت"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <div v-if="paymentStatus == 'paid' || paymentStatus == 'partial'">
                <v-row class="time-row mt-1">
                  <v-col
                    ><v-select
                      v-model="transferType"
                      :items="paymentTypes"
                      item-text="text"
                      item-value="value"
                      label="نحوه پرداخت*"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col v-if="paymentStatus == 'partial'">
                    <vuetify-money
                      v-model.number="partialAmount"
                      label="*مبلغ پرداخت (ریال)"
                      :valueWhenIsEmpty="whenIsEmpty"
                      :options="vMoneyOptions"
                      outlined
                      dense
                    />
                    <div
                      v-if="computedPartial"
                      class="red--text h4 mt-1 font-weight-bold"
                    >
                      *مبلغ وارد شده از مبلغ کل بیشتر میباشد.
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  class="time-row"
                  v-if="
                    transferType == 'card' ||
                    transferType == 'cheque' ||
                    transferType == 'cash-pos'
                  "
                >
                  <v-col>
                    <div v-if="transferType == 'card'">
                      <div
                        style="border: 1px grey solid; border-radius: 8px"
                        class="px-3 py-2"
                      >
                        <h5>اطلاعات کارت‌ها</h5>
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              outlined
                              dense
                              type="number"
                              class="mb-4"
                              v-model="cardNumberSender"
                              label="شماره کارت فرستنده*"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              outlined
                              dense
                              type="number"
                              v-model="cardNumberReciever"
                              label="شماره کارت گیرنده*"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div v-if="transferType == 'cash-pos'">
                      <div
                        style="border: 1px grey solid; border-radius: 8px"
                        class="px-3 py-2"
                      >
                        <h6 class="mb-2 red--text" v-if="!cashPosValid()">
                          ! مجموع نقدی و کارتخوان با مبلغ نهایی برابر نمی‌باشد.
                        </h6>
                        <vuetify-money
                          v-model="posAmount"
                          label="*مبلغ کارتخوان(ریال)"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          @input="onPosInput"
                          outlined
                          dense
                          class="mx-auto mb-2 details-hidden"
                        />
                        <vuetify-money
                          v-model="cashAmount"
                          label="*مبلغ نقدی(ریال)"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          outlined
                          dense
                          class="mx-auto details-hidden"
                        />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-btn
              class="submit-btn primary-btn mt-5"
              @click="visitType == 'دندانپزشکی' ? dentalSubmit() : submit()"
              :disabled="submitDisabled() || !cashPosValid()"
              :loading="addRequestBtnLoading"
              >ثبت</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- receipt -->
    <v-card
      class="pa-2"
      v-show="false"
      style="float: left"
      id="Receipt"
      v-if="isMounted"
    >
      <div class="container mb-0 pa-3" style="border: 2px solid black">
        <div
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>

        <p
          style="
            direction: rtl;
            font-weight: 900;
            padding: 10px;
            margin-bottom: 0;
          "
        >
          شماره نوبت: <span style="font-size = 16px">{{ visitNo }}</span>
          <br />{{ " نام پزشک: " + userData.drName }}
          <br />
          {{ "نام پرستار: " + userData.nurseName }}
          <br />
          {{ "نام بیمار: " + userData.name }}
          <br />
          {{ "کد اشتراک بیمار: " + userData.code }}
          <br />
          {{ "زمان مراجعه: " + userData.total.date }}
        </p>

        <b-table
          bordered
          v-if="visitType == 'public' || visitType == 'expert'"
          :fields="Fields"
          :items="Items"
          small="small"
          responsive
          style="padding: 10px; padding-top: 0"
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(number)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(cost)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ Number(data.item.cost).toLocaleString() }}
            </div>
          </template>
        </b-table>
        <p
          v-else
          style="direction: rtl; font-weight: 900; padding: 0 10px; margin: 0"
        >
          نوع درخواست: {{ visitType }}
        </p>
        <h4 style="padding: 0 10px; margin: 0">
          {{
            "مبلغ کل:" + Number(userData.total.cost).toLocaleString() + "ریال"
          }}
        </h4>
        <div
          v-if="
            newItems
              .map((x) => x.patientCost)
              .reduce((a, b) => parseInt(a) + parseInt(b), 0) != 0 ||
            visitType == 'مشاوره'
          "
        >
          <div style="text-align: right; font-weight: 900; padding: 0 10px">
            وضعیت پرداخت:
            {{
              paymentStatusOptions.filter((x) => x.value == "partial") &&
              Number(this.partialAmount) == Number(computedTotal)
                ? "پرداخت کامل"
                : paymentStatusOptions.filter(
                    (x) => x.value == paymentStatus
                  )[0].text
            }}
          </div>

          <div
            style="text-align: right; font-weight: 900; padding: 0 10px"
            v-if="paymentStatus == 'partial'"
          >
            {{
              "مبلغ پرداخت: " + Number(partialAmount).toLocaleString() + "ریال"
            }}
          </div>
          <div v-if="paymentStatus == 'partial' || paymentStatus == 'paid'">
            <div style="text-align: right; padding: 0 10px; font-weight: 900">
              {{
                transferType != "cash"
                  ? "نوع پرداخت: " +
                    paymentTypes.filter((x) => x.value == transferType)[0].text
                  : ""
              }}
            </div>
            <div
              v-if="
                transferType == 'card' ||
                transferType == 'cheque' ||
                transferType == 'cash-pos'
              "
              style="padding: 0 10px; font-weight: 900"
            >
              <div v-if="transferType == 'card'">
                شماره کارت فرستنده:
                {{ cardNumberSender ? cardNumberSender : "-" }}
                <br />
                شماره کارت فرستنده:
                {{ cardNumberReciever ? cardNumberReciever : "-" }}
              </div>
              <div v-if="transferType == 'cash-pos'">
                مبلغ کارتخوان:
                {{ posAmount ? Number(posAmount).toLocaleString() : "-" }} ریال
                <br />
                مبلغ نقدی:
                {{ cashAmount ? Number(cashAmount).toLocaleString() : "-" }}
                ریال
              </div>
            </div>
          </div>
        </div>
        <hr />
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">شماره تماس 02644267472</h4>
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </v-card>
    <!-- ِDental receipt -->
    <v-card
      class="pa-2"
      v-show="false"
      style="float: left"
      id="DentalReceipt"
      v-if="isMounted"
    >
      <div class="container mb-0" style="border: 2px solid black">
        <div
          class="pa-1"
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>

        <p style="direction: rtl; font-weight: 900; padding: 10px">
          شماره نوبت: <span style="font-size = 16px">{{ visitNo }}</span>
          <br />{{ " نام دندانپزشک: " + userData.drName }}
          <br />
          {{ "نام دستیار: " + (userData.nurseName ? userData.nurseName : "-") }}
          <br />
          {{ "نام بیمار: " + userData.name }}
          <br />
          {{ "کد اشتراک بیمار: " + userData.code }}
          <br />
          {{ "زمان مراجعه: " + userData.total.date }}
        </p>

        <b-table
          bordered
          v-if="visitType == 'دندانپزشکی'"
          :fields="Fields"
          :items="Items"
          small="small"
          responsive
          style="padding: 10px"
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(number)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(cost)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ Number(data.item.cost).toLocaleString() }}
            </div>
          </template>
        </b-table>
        <p v-else style="direction: rtl; font-weight: 900; padding: 10px">
          نوع درخواست: {{ visitType }}
        </p>
        <h4 style="padding: 10px">
          {{
            "مبلغ کل:" + Number(userData.total.cost).toLocaleString() + "ریال"
          }}
        </h4>
        <br />
        <div style="text-align: right; font-weight: 900; padding: 10px">
          وضعیت پرداخت:
          {{
            paymentStatusOptions.filter((x) => x.value == "partial") &&
            Number(this.partialAmount) == Number(computedDentistTotal)
              ? "پرداخت کامل"
              : paymentStatusOptions.filter((x) => x.value == paymentStatus)[0]
                  .text
          }}
        </div>

        <div
          style="text-align: right; font-weight: 900; padding: 10px"
          v-if="paymentStatus == 'partial'"
        >
          {{
            "مبلغ پرداخت: " + Number(partialAmount).toLocaleString() + "ریال"
          }}
        </div>
        <div v-if="paymentStatus == 'partial' || paymentStatus == 'paid'">
          <div style="text-align: right; padding: 10px; font-weight: 900">
            {{
              "نوع پرداخت: " +
              paymentTypes.filter((x) => x.value == transferType)[0].text
            }}
          </div>
          <div
            v-if="
              transferType == 'card' ||
              transferType == 'cheque' ||
              transferType == 'cash-pos'
            "
            style="padding: 10px; font-weight: 900"
          >
            <div v-if="transferType == 'card'">
              <br />
              شماره کارت فرستنده:
              {{ cardNumberSender ? cardNumberSender : "-" }}
              <br />
              شماره کارت فرستنده:
              {{ cardNumberReciever ? cardNumberReciever : "-" }}
            </div>
            <div v-if="transferType == 'cash-pos'">
              مبلغ کارتخوان:
              {{ posAmount ? Number(posAmount).toLocaleString() : "-" }} ریال
              <br />
              مبلغ نقدی:
              {{ cashAmount ? Number(cashAmount).toLocaleString() : "-" }} ریال
            </div>
          </div>
        </div>

        <hr />
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </v-card>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import VueBarcode from "vue-barcode";
import { mapGetters, mapActions } from "vuex";
function checkCodeMeli(code) {
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    barcode: VueBarcode,
  },
  data() {
    return {
      editInsuranceDialog: false,
      insuranceInfo: {
        insurType: "",
        insuranceExpirationDate: "",
      },
      busySubmit: true,
      newPatient: {
        name: "",
        gender: "",
        mobile: "",
        insurType: "",
        insurNo: "",
        natCode: "",
        insuranceLastSheet: false,
      },
      freeNames: [],
      dentistFreeNames: [],
      price: "",
      date: moment(new Date()).format("jYYYY/jMM/jDD HH:MM"),
      dateDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTime: moment(new Date()).format("HH:mm"),
      states: [
        { value: "فعال", text: "فعال" },
        { value: "غیرفعال", text: "غیرفعال" },
      ],
      patientName: "",
      visitsBusy: false,
      patientNames: [],
      outDoctor: "",
      isOut: false,
      insurType: "",
      newCurrentPage: 1,
      dentistNewCurrentPage: 1,
      newPerPage: 10,
      dentistNewPerPage: 10,
      newTotalRows: "",
      dentistNewTotalRows: "",
      newFilter: "",
      dentistNewFilter: "",
      busy: false,
      Busy: false,
      isBusy: false,
      freeServiceItems: [
        {
          name: "",
          cost: "",
          patientCost: "",
          expense: "",
          clinicShare: "",
          type: "",
        },
      ],
      dentistFreeServiceItems: [
        {
          name: "",
          cost: "",
          patientCost: "",
          expense: "",
          clinicShare: "",
          type: "",
        },
      ],
      newPatientDialog: false,
      freeOptions: [
        {
          value: "خدمات پرستاری",
          text: "خدمات پرستاری",
        },
        {
          value: "ویزیت",
          text: "ویزیت",
        },
        {
          value: "ویزیت تخصص",
          text: "ویزیت تخصص",
        },
        { value: "خدمات پزشک", text: "خدمات پزشک" },
      ],
      freeServiceFields: [
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "expense", label: "هزینه مصرفی (ریال)" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "type", label: "شیوه ارائه خدمت" },
        { key: "operation", label: "عملیات" },
      ],
      dentistFreeServiceFields: [
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "expense", label: "هزینه مصرفی (ریال)" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "operation", label: "عملیات" },
      ],
      serviceFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "patientCost", label: "پرداختی بیمار" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "type", label: "شیوه ارائه خدمت" },
        { key: "operation", label: "عملیات" },
      ],
      dentistServiceFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "patientCost", label: "پرداختی بیمار" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "operation", label: "عملیات" },
      ],
      serviceFilter: "",
      dentistServiceFilter: "",
      visitType: "",
      requestTypes: [
        { value: "public", text: "درمانگاه عمومی" },
        { value: "expert", text: "درمانگاه تخصصی" },
        { value: "مشاوره", text: "مشاوره" },
        // { value: "دندانپزشکی", text: "دندانپزشکی" },
        { value: "پاراکلینیک", text: "پاراکلینیک", disabled: true },
        // { value: "زنان", text: "زنان" },
        // { value: "مامائی", text: "مامائی" },
      ],
      serviceTotalRows: "",
      dentistTotalRows: "",
      serviceCurrentPage: 1,
      dentistServiceCurrentPage: 1,
      servicePerPage: 10,
      dentistServicePerPage: 10,
      serviceItems: [],
      dentistItems: [],
      newItems: [],
      dentistNewItems: [],
      reactivity: false,
      // reciept
      CurrentPage: 0,
      PerPage: 0,
      userService: "",
      reception: "",
      visitNo: "",
      userData: {},
      requestDate: "",
      userTotal: {},
      Fields: [
        { key: "name", label: "خدمت" },
        { key: "number", label: "تعداد" },
        { key: "cost", label: "قیمت" },
      ],
      serviceBusy: false,
      Items: [],
      isMounted: false,
      // reciept
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      drBusy: true,
      filterName: "",
      filterNatCode: "",
      filterCode: "",
      thisPageShiftDetails: {},
      patientInfo: [],
      changeMobileDialog: false,
      newMobile: "",
      changeBusy: false,
      requestId: "",
      transferType: "pos",
      cardNumberSender: "",
      cardNumberReciever: "",
      chequeBankName: "",
      chequeAmount: "",
      chequeNumber: "",
      chequeSayyad: "",
      chequeDueDate: "",
      chequeDescription: "",
      cashAmount: "",
      posAmount: "",
      addPatientBtnLoading: false,
      addRequestBtnLoading: false,
      insuranceExpirationDate: "",
      insuranceLastSheet: false,
      insurancePageNo: "",
      paymentStatusOptions: [
        { text: "پرداخت کامل", value: "paid" },
        { text: "پرداخت جزئی", value: "partial" },
      ],
      paymentStatusOptions2: [{ text: "پرداخت کامل", value: "paid" }],
      paymentStatus: "paid",
      printedMobile: "",
      partialAmount: 0,
      newServiceDialog: false,
      newService: {},
    };
  },
  methods: {
    ...mapActions(["getCurrent"]),
    isID(id) {
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },
    checkNatCode(id) {
      return id ? (checkCodeMeli(id) ? true : false) : true;
    },
    submitDisabled() {
      if (
        this.patientName &&
        this.visitType &&
        this.thisPageShiftDetails.docId &&
        this.paymentStatus
      ) {
        if (
          (this.paymentStatus == "partial" || this.paymentStatus == "paid") &&
          this.transferType
        ) {
          if (this.transferType == "card") {
            if (this.cardNumberSender && this.cardNumberReciever) {
              if (this.paymentStatus == "partial") {
                if (!this.computedPartial) {
                  return false;
                } else return true;
              }
              return false;
            } else return true;
          }
          if (this.transferType == "cheque") {
            if (
              this.chequeBankName &&
              this.chequeNumber &&
              this.chequeDueDate
            ) {
              if (this.paymentStatus == "partial") {
                if (!this.computedPartial) {
                  return false;
                } else return true;
              }
              return false;
            } else return true;
          }
          if (this.transferType == "cash-pos") {
            if (this.cashAmount && this.posAmount) {
              if (this.paymentStatus == "partial") {
                if (!this.computedPartial) {
                  return false;
                } else return true;
              }
              return false;
            } else return true;
          }
          if (this.transferType == "pos" || this.transferType == "cash") {
            if (this.paymentStatus == "partial") {
              if (!this.computedPartial) {
                return false;
              } else return true;
            }
          }
        } else if (
          (this.paymentStatus == "partial" || this.paymentStatus == "paid") &&
          !this.transferType
        ) {
          return true;
        }
      } else return true;
    },
    openNewPatient() {
      (this.newPatient = {
        firstName: "",
        lastName: "",
        gender: "",
        mobile: "",
        insurType: "",
        insurNo: "",
        natCode: "",
        insuranceExpirationDate: "",
        insuranceLastSheet: false,
      }),
        (this.newPatientDialog = true);
    },

    submitNewPatient() {
      this.addPatientBtnLoading = true;
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/new",

          {
            gender: this.newPatient.gender,
            firstName: this.newPatient.firstName,
            lastName: this.newPatient.lastName,
            mobile: this.newPatient.mobile,
            insuranceId: this.newPatient.insurType,
            insurNo: this.newPatient.insurNo,
            natCode: this.newPatient.natCode,
            insuranceExpirationDate: this.newPatient.insuranceLastSheet
              ? ""
              : this.newPatient.insuranceExpirationDate,
            insuranceLastSheet: this.newPatient.insuranceLastSheet,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.filterName =
              this.newPatient.firstName + " " + this.newPatient.lastName;
            this.filterNatCode = this.newPatient.natCode;
            this.filterPatients();
            this.toast("بیمار با موفقیت ثبت شد.", "success");
            this.newPatient = {
              firstName: "",
              lastName: "",
              gender: "",
              mobile: "",
              insurType: "",
              insurNo: "",
              natCode: "",
              insuranceExpirationDate: "",
              insuranceLastSheet: false,
            };
            this.newPatientDialog = false;
            this.patientName = res.data;
            this.isBusy = false;
            this.addPatientBtnLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.isBusy = false;
            this.addPatientBtnLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
          this.addPatientBtnLoading = false;
        });
    },
    editPatientInsurance() {
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/editInsurance",
          {
            patientId: this.patientName,
            insuranceId: this.insuranceInfo.insuranceId,
            insuranceExpirationDate: this.insuranceInfo.insuranceLastSheet
              ? ""
              : this.insuranceInfo.insuranceExpirationDate,
            insuranceLastSheet: this.insuranceInfo.insuranceLastSheet,
            insurNo: this.insuranceInfo.insurNo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast("بیمه با موفقیت ویرایش شد.", "success");
            this.editInsuranceDialog = false;
            this.patientVisitsNumber();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    patientVisitsNumber() {
      this.visitsBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/visitsNumber",

          {
            patientId: this.patientName,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.visitsBusy = false;
          if (res.status == 200) {
            this.patientInfo = res.data;
            this.insuranceInfo = JSON.parse(JSON.stringify(res.data));
            this.reactivity = !this.reactivity;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.visitsBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    changeMobileNumber() {
      this.changeBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/editMobile",

          {
            patientId: this.patientName,
            mobile: this.newMobile,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.changeBusy = false;
          if (res.status == 200) {
            this.changeMobileDialog = false;
            this.newMobiles = "";
            this.patientVisitsNumber();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.changeBusy = false;
          this.changeMobileDialog = false;
          this.newMobile = "";
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    addtoNewService(item) {
      this.serviceTotalRows = this.serviceItems.length;
      this.newItems.push(item);
      this.newTotalRows = this.newItems.length;
    },
    addtoNewDentistService(item) {
      this.dentistTotalRows = this.dentistItems.length;
      this.dentistNewItems.push(item);
      this.dentistNewTotalRows = this.dentistNewItems.length;
    },
    addFreeService(item) {
      item.patientCost = item.cost;
      item.clinicShare = item.clinicShare ? item.clinicShare : "0";
      item.expense = item.expense ? item.expense : "0";
      item.id = this.freeNames.filter((x) => x.id == item.name)[0].id;
      item.docPercent = this.freeNames.filter(
        (x) => x.id == item.name
      )[0].docPercent;
      item.name = "*" + this.freeNames.filter((x) => x.id == item.name)[0].text;
      this.serviceTotalRows = this.serviceItems.length;
      this.newItems.push(item);
      this.newTotalRows = this.newItems.length;
      this.freeServiceItems = [
        {
          name: "",
          cost: "",
          expense: "",
          clinicShare: "",
          patientCost: "",
          type: "",
        },
      ];
      this.newService = {};
      this.newServiceDialog = false;
    },
    addFreeDentistService(item) {
      item.patientCost = item.cost;
      item.clinicShare = item.clinicShare ? item.clinicShare : "0";
      item.expense = item.expense ? item.expense : "0";
      item.id = this.dentistFreeNames.filter((x) => x.id == item.name)[0].id;
      item.docPercent = this.dentistFreeNames.filter(
        (x) => x.id == item.name
      )[0].docPercent;
      item.name =
        "*" + this.dentistFreeNames.filter((x) => x.id == item.name)[0].text;
      this.dentistTotalRows = this.dentistItems.length;
      this.dentistNewItems.push(item);
      this.dentistNewTotalRows = this.dentistNewItems.length;
      this.dentistFreeServiceItems = [
        {
          name: "",
          cost: "",
          expense: "",
          clinicShare: "",
          patientCost: "",
          type: "",
        },
      ];
      this.newService = {};
      this.newServiceDialog = false;
    },
    delService(order) {
      this.newItems.splice(order, 1);
    },
    dentistDelService(order) {
      this.dentistNewItems.splice(order, 1);
    },
    clinicServices() {
      if (this.visitType == "public" || this.visitType == "expert") {
        this.newItems = [];
        this.freeServiceItems = [
          {
            name: "",
            cost: "",
            expense: "",
            clinicShare: "",
            patientCost: "",
            type: "",
          },
        ];
        this.serviceFilter = "";
        this.serviceCurrentPage = 1;
        this.serviceBusy = true;
        this.$http
          .post(
            this.baseUrl + "/clinic/services/servicesPerClinic",

            {
              clinic: this.visitType,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.serviceBusy = false;
            if (res.status == 200) {
              this.serviceItems = res.data;
              this.freeNames = this.serviceItems.map((x) => {
                return {
                  id: x.id,
                  text: x.name,
                  docPercent: x.docPercent,
                };
              });
              this.serviceTotalRows = this.serviceItems.length;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.serviceBusy = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      } else if (this.visitType == "دندانپزشکی") {
        this.dentistServices();
      }
    },
    deFaultDoc() {
      if (this.visitType == "public") {
        this.thisPageShiftDetails.docId = this.shiftDetails.docId;
        this.thisPageShiftDetails.nurseId = this.shiftDetails.nurseId;
      } else if (this.visitType == "دندانپزشکی") {
        this.thisPageShiftDetails.docId = this.shiftDetails.dentistId;
        this.thisPageShiftDetails.nurseId = this.shiftDetails.nurseDentistId;
      } else {
        this.thisPageShiftDetails.docId = "";
      }
    },
    filterPatients() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/filter",

          {
            name: this.filterName,
            natCode: this.filterNatCode || "",
            code: this.filterCode || "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.patientName = "";
            this.patientNames = res.data.map((x) => {
              return {
                id: x.id,
                text: x.name,
                insurance: x.insuranceId,
                nameW: x.nameW,
                code: x.code,
              };
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    dentistServices() {
      this.vLoading = true;
      this.serviceBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentist/services",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 200) {
            this.dentistItems = res.data;
            this.dentistFreeNames = this.dentistItems.map((x) => {
              return {
                id: x.id,
                text: x.name,
                docPercent: x.docPercent,
              };
            });
            this.serviceBusy = false;
            this.dentistTotalRows = this.dentistItems.length;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    shiftInfo() {
      setTimeout(() => {
        this.thisPageShiftDetails = JSON.parse(
          JSON.stringify(this.shiftDetails)
        );
      }, 600);
    },
    submit() {
      this.vLoading = true;
      this.busySubmit = true;
      this.addRequestBtnLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/visits/new",

          {
            visitType:
              this.visitType == "مشاوره" ? this.visitType : "خدمات عمومی",
            patientId: this.patientName,
            nurseId: this.thisPageShiftDetails.nurseId,
            insuranceId: this.patientInfo.insuranceId,
            docId: this.thisPageShiftDetails.docId,
            shiftId: this.shiftDetails.id,
            outDoctor: this.outDoctor,
            isOut: this.isOut === true ? 1 : 0,
            date: this.dateDate + this.dateTime,
            insuranceExpirationDate: this.patientInfo.insuranceLastSheet
              ? ""
              : this.patientInfo.insuranceExpirationDate,
            insuranceLastSheet: this.patientInfo.insuranceLastSheet,
            insurNo: this.patientInfo.insurNo,
            insurancePageNo: this.insurancePageNo,
            totalCost:
              this.newItems.length != 0
                ? String(
                    this.newItems
                      .map((x) => x.patientCost)
                      .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                  )
                : this.price,
            paymentStatus:
              this.transferType == "charity"
                ? "charityPaid"
                : this.paymentStatus == "partial" && !this.partialAmount
                ? "unpaid"
                : this.paymentStatus == "partial" &&
                  Number(this.partialAmount) == Number(this.computedTotal)
                ? "paid"
                : this.paymentStatus,
            paidCost:
              this.paymentStatus == "partial"
                ? this.partialAmount
                : this.paymentStatus == "paid"
                ? this.newItems
                    .map((x) => x.patientCost)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                : "0",
            transferType: this.transferType,
            charityBoxId:
              this.transferType == "charity" ? this.generalCharityBoxId : "",
            chequeAmount:
              this.transferType == "cheque"
                ? this.newItems.length != 0
                  ? String(
                      this.newItems
                        .map((x) => x.patientCost)
                        .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                    )
                  : this.price
                : "",
            chequeNumber:
              this.transferType == "cheque" ? this.chequeNumber : "",
            chequeBankName:
              this.transferType == "cheque" ? this.chequeBankName : "",
            chequeDueDate:
              this.transferType == "cheque" ? this.chequeDueDate : "",
            chequeSayyad:
              this.transferType == "cheque" ? this.chequeSayyad : "",
            chequeDescription:
              this.transferType == "cheque" ? this.chequeDescription : "",
            cardNumberSender:
              this.transferType == "card" ? this.cardNumberSender : "",
            cardNumberReciever:
              this.transferType == "card" ? this.cardNumberReciever : "",
            cash: this.transferType == "cash-pos" ? this.cashAmount : "",
            pos: this.transferType == "cash-pos" ? this.posAmount : "",
            services: JSON.stringify(this.newItems),
            //partialPayment: this.partialPayment,
            //partialPaymentAmount: this.partialPayment
            //  ? this.partialPaymentAmount
            //  : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = true;
          this.getCurrent();
          if (res.status == 201) {
            let myNurseName;
            let myDocName;
            try {
              myDocName =
                "دکتر " +
                this.drNames.filter((x) => x.id == res.data.docId)[0].nameW;
            } catch (error) {
              myDocName = "-";
            }
            try {
              myNurseName = this.nurseNames.filter(
                (x) => x.id == res.data.nurseId
              )[0].text;
            } catch (error) {
              myNurseName = "-";
            }
            this.userData = new Object({
              name: this.patientNames.filter((x) => x.id == this.patientName)[0]
                .nameW, // نام بدون کدملی
              code: this.patientNames.filter((x) => x.id == this.patientName)[0]
                .code, //کد اشتراک
              drName: myDocName,
              nurseName: myNurseName,
              total: res.data,
            });
            this.Items = res.data.services;
            this.visitNo = res.data.visitNo;
            this.reception = this.userName;

            this.filterName = "";
            this.filterNatCode = "";

            this.toast("درخواست خدمت به ثبت رسید", "success");
            this.newPatient = "";

            this.requestId = res.data.id;
            this.isMounted = true;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.busySubmit = false;
          }
        })
        .then(() => {
          this.popup();
          this.isMounted = false;
        })
        .then(() => {
          this.price = "";
          this.date = moment(new Date()).format("jYYYY/jMM/jDD HH:MM");
          this.dateDate = moment(new Date()).format("jYYYY/jMM/jDD");
          this.dateTime = moment(new Date()).format("HH:mm");
          this.patientName = "";
          this.outDoctor = "";
          this.isOut = false;
          this.insurType = "";
          this.newCurrentPage = 1;
          this.dentistNewCurrentPage = 1;
          this.shiftInfo();
          this.newTotalRows = "";
          this.paymentStatus = "";
          this.partialAmount = "";
          (this.dentistNewTotalRows = ""), (this.newFilter = "");
          this.newPatient = {
            firstName: "",
            lastName: "",
            gender: "",
            mobile: "",
            insurType: "",
            insurNo: "",
            natCode: "",
            insuranceExpirationDate: "",
            insuranceLastSheet: false,
          };
          this.patientInfo = {};
          this.insurancePageNo = "";
          //transferType details set to empty for next request
          this.transferType = "pos";
          this.cardNumberSender = "";
          this.cardNumberReciever = "";
          this.chequeBankName = "";
          this.chequeAmount = "";
          this.chequeNumber = "";
          this.chequeSayyad = "";
          this.chequeDueDate = "";
          this.chequeDueDate = "";
          this.chequeDescription = "";
          this.cashAmount = "";
          this.posAmount = "";
          this.busy = false;

          this.dentistNewFilter = "";

          this.serviceFilter = "";
          this.visitType = "";

          (this.dentistServiceFilter = ""), (this.serviceTotalRows = "");
          this.serviceCurrentPage = 1;
          this.servicePerPage = 10;
          this.serviceBusy = true;
          this.newItems = [];

          this.dentistTotalRows = "";
          (this.dentistServiceCurrentPage = 1),
            (this.dentistServicePerPage = 10),
            (this.dentistNewItems = []),
            // reciept
            (this.CurrentPage = 0);
          this.PerPage = 0;
          this.userService = "";
          this.reception = "";
          this.visitNo = "";
          this.userData = {};
          this.requestDate = "";
          this.userTotal = {};

          this.Items = [];
          this.addRequestBtnLoading = false;
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.busySubmit = false;
          this.addRequestBtnLoading = false;
        });
    },
    dentalSubmit() {
      this.vLoading = true;
      this.busySubmit = true;
      this.addRequestBtnLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentist/newVisit",
          {
            patientId: this.patientName,
            docId: this.thisPageShiftDetails.docId,
            shiftId: this.shiftDetails.id,
            date: this.dateDate + this.dateTime,
            insuranceExpirationDate: this.patientInfo.insuranceLastSheet
              ? ""
              : this.patientInfo.insuranceExpirationDate,
            insuranceLastSheet: this.patientInfo.insuranceLastSheet,
            insurNo: this.patientInfo.insurNo,
            insurancePageNo: this.insurancePageNo,
            insuranceId: this.patientInfo.insuranceId,
            paidCost:
              this.paymentStatus == "partial"
                ? this.partialAmount
                : this.paymentStatus == "paid"
                ? this.dentistNewItems
                    .map((x) => x.patientCost)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                : "0",
            totalCost:
              this.dentistNewItems.length != 0
                ? String(
                    this.dentistNewItems
                      .map((x) => x.patientCost)
                      .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                  )
                : this.price,
            paymentStatus:
              this.paymentStatus == "partial" && !this.partialAmount
                ? "unpaid"
                : this.paymentStatus == "partial" &&
                  Number(this.partialAmount) ==
                    Number(this.computedDentistTotal)
                ? "paid"
                : this.paymentStatus,

            transferType: this.transferType,
            chequeAmount:
              this.transferType == "cheque"
                ? this.dentistNewItems.length != 0
                  ? String(
                      this.dentistNewItems
                        .map((x) => x.patientCost)
                        .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                    )
                  : this.price
                : "",
            chequeNumber:
              this.transferType == "cheque" ? this.chequeNumber : "",
            chequeBankName:
              this.transferType == "cheque" ? this.chequeBankName : "",
            chequeDueDate:
              this.transferType == "cheque" ? this.chequeDueDate : "",
            chequeSayyad:
              this.transferType == "cheque" ? this.chequeSayyad : "",
            chequeDescription:
              this.transferType == "cheque" ? this.chequeDescription : "",
            cardNumberSender:
              this.transferType == "card" ? this.cardNumberSender : "",
            cardNumberReciever:
              this.transferType == "card" ? this.cardNumberReciever : "",
            cash: this.transferType == "cash-pos" ? this.cashAmount : "",
            pos: this.transferType == "cash-pos" ? this.posAmount : "",
            services: JSON.stringify(this.dentistNewItems),
            nurseId: this.thisPageShiftDetails.nurseId,
            outDoctor: this.outDoctor,
            isOut: this.isOut === true ? 1 : 0,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.getCurrent();
          if (res.status == 201) {
            let myNurseName;
            let myDocName;
            try {
              myDocName =
                "دکتر " +
                this.drNames.filter((x) => x.id == res.data.docId)[0].nameW;
            } catch (error) {
              myDocName = "-";
            }
            try {
              myNurseName = this.nurseNames.filter(
                (x) => x.id == res.data.nurseId
              )[0].text;
            } catch (error) {
              myNurseName = "-";
            }

            this.userData = new Object({
              name: this.patientNames.filter((x) => x.id == this.patientName)[0]
                .nameW, // نام بدون کدملی
              code: this.patientNames.filter((x) => x.id == this.patientName)[0]
                .code, //کد اشتراک
              drName: myDocName,
              nurseName: myNurseName,
              total: res.data,
            });

            this.Items = res.data.services;

            this.visitNo = res.data.visitNo;

            this.reception = this.userName;

            this.filterName = "";
            this.filterNatCode = "";

            this.toast("درخواست خدمت به ثبت رسید", "success");
            this.newPatient = "";

            this.requestId = res.data.id;
            this.isMounted = true;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.addRequestBtnLoading = false;
            this.vLoading = false;
            this.busySubmit = false;
          }
        })
        .then(() => {
          this.Dentalpopup();
          this.isMounted = false;
        })
        .then(() => {
          this.patientInfo = {};
          this.insurancePageNo = "";
          this.price = "";

          this.date = moment(new Date()).format("jYYYY/jMM/jDD HH:MM");

          this.dateDate = moment(new Date()).format("jYYYY/jMM/jDD");

          this.dateTime = moment(new Date()).format("HH:mm");

          this.patientName = "";
          this.outDoctor = "";
          this.isOut = false;
          this.insurType = "";
          this.newCurrentPage = 1;
          this.dentistNewCurrentPage = 1;

          this.shiftInfo();

          this.newTotalRows = "";

          (this.dentistNewTotalRows = ""), (this.newFilter = "");
          this.newPatient = {
            name: "",
            gender: "",
            mobile: "",
            insurType: "",
            insurNo: "",
            natCode: "",
            insuranceLastSheet: false,
          };
          this.paymentStatus = "";
          this.partialAmount = "";
          //transferType details set to empty for next request
          this.transferType = "pos";
          this.cardNumberSender = "";
          this.cardNumberReciever = "";
          this.chequeBankName = "";
          this.chequeAmount = "";
          this.chequeNumber = "";
          this.chequeSayyad = "";
          this.chequeDueDate = "";
          this.chequeDueDate = "";
          this.chequeDescription = "";
          this.cashAmount = "";
          this.posAmount = "";
          this.busy = false;

          this.dentistNewFilter = "";

          this.serviceFilter = "";
          this.visitType = "";

          (this.dentistServiceFilter = ""), (this.serviceTotalRows = "");
          this.serviceCurrentPage = 1;
          this.servicePerPage = 10;
          this.serviceBusy = true;
          this.newItems = [];

          this.dentistTotalRows = "";
          (this.dentistServiceCurrentPage = 1),
            (this.dentistServicePerPage = 10),
            (this.dentistNewItems = []),
            // reciept
            (this.CurrentPage = 0);
          this.PerPage = 0;
          this.userService = "";
          this.reception = "";
          this.visitNo = "";

          this.userData = {};
          this.requestDate = "";
          this.userTotal = {};
          this.paymentStatus = "";
          this.paymentAmount = "";
          this.Items = [];
          this.addRequestBtnLoading = false;
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.busySubmit = false;
          this.addRequestBtnLoading = false;
        });
    },
    onPosInput() {
      let total;
      if (this.paymentStatus == "partial") {
        total = this.partialAmount;
      } else if (this.paymentStatus == "paid") {
        if (this.visitType == "دندانپزشکی") {
          total =
            this.dentistNewItems.length != 0
              ? String(
                  this.dentistNewItems
                    .map((x) => x.patientCost)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                )
              : 0;
        } else {
          total =
            this.newItems.length != 0
              ? String(
                  this.newItems
                    .map((x) => x.patientCost)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                )
              : this.price;
        }
      }

      if (total) {
        if (parseInt(this.posAmount) < parseInt(total)) {
          this.cashAmount = parseInt(total) - parseInt(this.posAmount);
        } else {
          this.cashAmount = 0;
        }
      }
    },
    cashPosValid() {
      let total;
      if (this.paymentStatus == "partial") {
        total = this.partialAmount;
      } else if (this.paymentStatus == "paid") {
        if (this.visitType == "دندانپزشکی") {
          total =
            this.dentistNewItems.length != 0
              ? String(
                  this.dentistNewItems
                    .map((x) => x.patientCost)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                )
              : 0;
        } else {
          total =
            this.newItems.length != 0
              ? String(
                  this.newItems
                    .map((x) => x.patientCost)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                )
              : this.price;
        }
      }

      if (this.transferType == "cash-pos") {
        if (this.cashAmount && this.posAmount) {
          if (
            parseInt(total) ==
            parseInt(this.cashAmount) + parseInt(this.posAmount)
          ) {
            return true;
          } else return false;
        } else return false;
      } else {
        return true;
      }
    },
    popup() {
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Receipt").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    Dentalpopup() {
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>DentalReceipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(
        document.getElementById("DentalReceipt").innerHTML
      );
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    serviceonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.serviceTotalRows = filteredItems.length;
      this.serviceCurrentPage = 1;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.newTotalRows = filteredItems.length;
      this.newCurrentPage = 1;
    },
    dentistServiceonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.dentistTotalRows = filteredItems.length;
      this.dentistServiceCurrentPage = 1;
    },
    dentistonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.dentistNewTotalRows = filteredItems.length;
      this.dentistNewCurrentPage = 1;
    },
  },
  computed: {
    ...mapGetters([
      "drNames",
      "nurseNames",
      "insurTypes",
      "shiftDetails",
      "userName",
      "generalCharityBox",
      "generalCharityBoxId",
      "paymentTypes",
    ]),
    computedDoctors() {
      let doctors = this.drNames;
      if (this.visitType == "public") {
        doctors = this.drNames.filter((x) => x.label.includes("عمومی"));
      } else if (this.visitType == "دندانپزشکی") {
        doctors = this.drNames.filter((x) => x.label.includes("دندانپزشک"));
      } else {
        doctors = this.drNames.filter(
          (x) => !x.label.includes("دندانپزشک") && !x.label.includes("عمومی")
        );
      }
      return doctors;
    },
    computedPartial() {
      if (this.visitType == "دندانپزشکی") {
        if (
          this.partialAmount >
          this.dentistNewItems
            .map((x) => x.patientCost)
            .reduce((a, b) => parseInt(a) + parseInt(b), 0)
        ) {
          return true;
        } else return false;
      } else if (this.visitType == "مشاوره") {
        if (this.partialAmount > this.price) {
          return true;
        } else return false;
      } else {
        if (
          this.partialAmount >
          this.newItems
            .map((x) => x.patientCost)
            .reduce((a, b) => parseInt(a) + parseInt(b), 0)
        ) {
          return true;
        } else return false;
      }
    },
    computedTotal() {
      if (this.newItems.length != 0) {
        return this.newItems
          .map((x) => x.patientCost)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0);
      } else {
        return this.price;
      }
    },
    computedDentistTotal() {
      if (this.dentistNewItems.length != 0) {
        return this.dentistNewItems
          .map((x) => x.patientCost)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0);
      } else {
        return this.price;
      }
    },
    computedServiceFields() {
      if (this.deviceType == "mobile") {
        return [
          { key: "name", label: "نام خدمت" },
          { key: "type", label: "شیوه ارائه خدمت" },
          { key: "operation", label: "عملیات" },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "name", label: "نام خدمت" },
          { key: "cost", label: "قیمت (ریال)" },
          { key: "patientCost", label: "پرداختی بیمار" },
          { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
          { key: "type", label: "شیوه ارائه خدمت" },
          { key: "operation", label: "عملیات" },
        ];
      }
    },
    computedDentistServiceFields() {
      if (this.deviceType == "mobile") {
        return [
          { key: "index", label: "#" },
          { key: "name", label: "نام خدمت" },
          { key: "operation", label: "عملیات" },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "name", label: "نام خدمت" },
          { key: "cost", label: "قیمت (ریال)" },
          { key: "patientCost", label: "پرداختی بیمار" },
          { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
          { key: "operation", label: "عملیات" },
        ];
      }
    },
    computedServiceTypes() {
      if (this.visitType == "public") {
        return ["خدمات پرستاری", "ویزیت", "خدمات پزشک", "اقلام مصرفی"];
      } else if (this.visitType == "expert") {
        return ["ویزیت تخصصی", "خدمات تخصصی"];
      }
    },
  },
  mounted() {
    this.shiftInfo();
  },
};
</script>
<style lang="scss">
.infoMessage {
  text-align: center;
  border: 1px dotted black;
  padding: 10px 5px;
  font-size: 16px;
}

.divider {
  display: flex;

  &:before,
  &:after {
    content: "";
    flex: 1;
  }
}
.line {
  align-items: center;
  margin: 1em -1em;

  &:before,
  &:after {
    height: 1px;
    margin: 0 1em;
  }
}

.one-line {
  &:before,
  &:after {
    background: #00a7b7;
  }
}

hr.insurance-hr {
  overflow: visible; /* For IE */
  padding: 0;
  border: none;
  border-top: medium solid #333;
  color: #333;
  text-align: center;
}
hr.insurance-hr:after {
  content: "اطلاعات بیمه بیمار";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: white;
}

.pi-text {
  font-size: 15px;
}
</style>
